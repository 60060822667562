
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import DefaultLayout from '@/components/DefaultLayout.vue';
import OrderSummary from '@/components/OrderSummary.vue';
import OrderlineCard from '@/components/cardComponents/OrderlineCard.vue';
import RushIcon from '@/components/Icons/RushIcon.vue';
import J1lImg from '@/components/J1lImg.vue';
import OrderEditPo from '@/components/OrderEditPo.vue';
import PaymentTerms from '@/components/PaymentTerms.vue';
import OrderInvoiceDialog from '@/components/OrderInvoiceDialog.vue';
import OrderPacklistDialog from '@/components/OrderPacklistDialog.vue';
import {
  OrderlineDetails,
  OrderlineGroup,
  OrderlinePurchaseStateTypes,
  RushOption,
  ShippingDeliveryMethod
} from '@/store/models/OrderlineModel';
import { OrdersService } from '@/services/orders-service';
import { AddressService } from '@/services/address-service';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { StringHelper } from '@/helpers/StringHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { HasLikeItems } from '@/helpers/OrderlineHelper';
import { ConstValues } from '@/ConstValues';
import { PaymentProfile } from '@/store/models/PaymentModel';
import { Confirm } from '@/helpers/AuthHelper';
@Component({
  components: {
    DefaultLayout,
    OrderSummary,
    OrderlineCard,
    J1lImg,
    RushIcon,
    OrderEditPo,
    PaymentTerms,
    OrderInvoiceDialog,
    OrderPacklistDialog
  }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
    OrderSummary: HTMLFormElement;
    OrderlineCard: HTMLFormElement;
    OrderEditPo: HTMLFormElement;
    itemSelect: HTMLFormElement;
    quantityField: HTMLFormElement;
    OrderInvoiceDialog: HTMLFormElement;
    OrderPacklistDialog: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  @Getter('getCurrentOrder', { namespace: 'orderlines' })
  currentOrder!: OrderlineDetails[];
  @Getter('getItemPartNumber', { namespace: 'items' })
  items: any;
  @Getter('getItemPartNumber', { namespace: 'items' })
  getPartNumbers: any;
  @Getter('getCustomer', { namespace: 'customer' })
  currentCustomer: any;
  @Getter('getDeliveryMethodFromRushDay', { namespace: 'orderlines' })
  getDeliveryMethodFromRushDay: (
    dayValue: number,
    isInternational: boolean
  ) => ShippingDeliveryMethod;
  @Action('ClearCurrentOrder', { namespace: 'orderlines' })
  clearCurrentOrder!: any;
  @Action('ChangePO', { namespace: 'orderlines' })
  changePOInStore: any;
  @Action('getCurrentCustomer', { namespace: 'customer' })
  getCurrentCustomerStore: any;
  @Action('refreshOrderline', { namespace: 'orderlines' })
  refreshOrderline!: any;
  @Action('refreshMultipleOrderlines', { namespace: 'orderlines' })
  refreshMultipleOrderlines!: any;
  @Action('RemoveOrdersByPO', { namespace: 'orderlines' })
  removeOrdersByPO: any;
  @Action('GetCustomerItemsLimited', { namespace: 'items' })
  getCustomerItemsLimited: any;
  @Mutation('setCurrentOrder', { namespace: 'orderlines' })
  setCurrentOrderInStore: any;
  @Mutation('orderlinesLoaded', { namespace: 'orderlines' })
  setOrderlines!: any;
  @Mutation('refreshOrderlinesByParameter', { namespace: 'orderlines' })
  refreshOrderlinesByParameter!: any;
  /* Watchers */
  @Watch('search')
  searching(val: string) {
    this.refreshImageForSearch();
  }
  @Watch('quantity')
  onQuantityChange(val: number) {
    this.generateNewOrderline();
  }
  /* Data */
  orderlines: OrderlineDetails[] = [];
  newOrderline: OrderlineDetails = new OrderlineDetails();
  selectedItem: {
    PartNumber: string;
    Page: number;
    Description: string;
    Id: string;
  } | null = null;
  quantity: number | null = null;
  selectedRollDirection: number | null = null;
  defaultCustomerRollDirection!: number;
  po: string = '';
  search: string = '';
  rushHint: string = '';
  refreshImg: boolean = true;
  rushOptions: RushOption[] = [];
  selectedRushOption: RushOption = new RushOption();
  paymentProfileId: string = '';
  shouldShowNewLineItemDialog: boolean = false;
  isGettingOrderDetails: boolean = false;
  isCancellingOrder: boolean = false;
  isGettingCustomerItems: boolean = false;
  isCalculatingPricingData: boolean = false;
  isTextInputValid: boolean = false;
  isAddingOrderline: boolean = false;
  shouldShowAlert: boolean = false;
  selectedAddress: any = null;
  shippingAddresses: any[] = [];
  extendedPrice: number = 0;
  variableDataFee: number = 0;
  rushFee: number = 0;
  setUpFee: number = 0;
  extraRollFee: number = 0;
  typingTimer: any;
  doneTypingInterval: number = 500;
  paymentMethod: PaymentProfile = new PaymentProfile();
  alertList: { text: string; type: string }[] = [];
  orderlineGroup: OrderlineGroup = new OrderlineGroup();
  /* Utility Functions */
  onAutocompleteChange() {
    this.$refs.itemSelect.blur();
    this.$refs.quantityField.focus();
  }
  openNewLineDialog() {
    this.shouldShowNewLineItemDialog = true;
  }
  async hasPastDueInvoices() {
    try {
      const { data } = await OrdersService.GetPastDueOrders();
      if (data) {
        Confirm(
          () => {
            this.goToInvoices();
          },
          'You have invoices past due',
          'You have invoices past due, would you like to pay them now?',
          "I'll pay it later",
          'Pay now'
        );
      }
    } catch (error) {
      console.log(error);
    }
  }
  openInvoiceDialog() {
    this.orderlineGroup = {
      Orderlines: this.orderlines,
      GroupByValue: this.po,
      Page: 1
    };
    setTimeout(() => {
      this.$refs.OrderInvoiceDialog.openDialog();
    }, 100);
  }
  openPacklistDialog() {
    this.orderlineGroup = {
      Orderlines: this.orderlines,
      GroupByValue: this.po,
      Page: 1
    };
    setTimeout(() => {
      this.$refs.OrderPacklistDialog.openDialog();
    }, 100);
  }
  goToInvoices() {
    window.open(`${process.env.VUE_APP_BASE_URL}/invoicemanagement`);
    this.$refs.OrderSummary.saveForLater(false);
  }
  isMobile() {
    return this.$refs.defaultActions ? this.$refs.defaultActions.shouldUseMobile : false;
  }
  async refreshOrderlineAndState(
    orderline: OrderlineDetails,
    onSuccessText: string,
    shouldRecalculate: boolean = false
  ) {
    if (orderline.RefreshAll) {
      await this.getOrderlinesByPO();
      if (onSuccessText) this.addAlert(onSuccessText, 'success');
      return;
    }
    let newOl = await this.refreshOrderline(orderline.Id);
    let ol = this.orderlines.findIndex(ol => ol.Id == orderline.Id);
    if (ol >= 0) {
      this.orderlines[ol].SetData(newOl);
    }
    if (shouldRecalculate) this.$refs.OrderSummary.calculateSubTotal();
    if (onSuccessText) this.addAlert(onSuccessText, 'success');
  }
  addAlert(alertText: string, alertType: string) {
    this.alertList.push({ text: alertText, type: alertType });
  }
  async setPO(val: string) {
    (this.orderlines as OrderlineDetails[]).map((ol: OrderlineDetails) => (ol.PO = val));
    if (this.$route.params.po !== val) {
      let oldPO = this.$route.params.po;
      try {
        if (val) {
          // await OrdersService.ChangeOrderPO(oldPO, val);
          this.changePOInStore({ oldPO: oldPO, newPO: val });
          this.$refs.OrderSummary.customerPO = val;
          // this.$router.push({
          //   name: 'OrderDetails',
          //   params: { po: val }
          // });
          this.addAlert('PO changed successfully', 'success');
        }
      } catch (e) {
        this.addAlert(
          'There was an error changing your PO, please contact technical support',
          'error'
        );
      }
    }
  }
  async changeAddress(val: any) {
    if (val.orderId) {
      let orderline = this.orderlines.find(ol => ol.Id == val.orderId);
      if (orderline) {
        orderline.Address = val.shippingAddress;
        orderline.RollDirection.Id = val.shippingAddress.Options.RollDirectionId;
        await OrdersService.ChangeOrderlineShippingAddress({
          Id: val.orderId,
          ShippingAddressId: val.shippingAddress.Id,
          RollDirectionId: val.shippingAddress.Options.RollDirectionId,
          ShouldChangeAllShippingAddress: val.shippingAddress.ShouldChangeAllAddresses
        });
        if (val.shippingAddress.ShouldChangeAllAddresses) {
          this.orderlines.forEach(ol => this.refreshOrderlineAndState(ol, ''));
        } else {
          this.refreshOrderlineAndState(orderline, 'Address changed successfully');
        }
      }
    }
  }
  async changeQuantity(val: { orderId: string; quantity: number }) {
    if (val.orderId) {
      let orderline = this.orderlines.find(
        (ol: OrderlineDetails) => ol.Id == val.orderId
      );
      if (orderline) {
        orderline.Quantity = val.quantity;
        try {
          await OrdersService.ChangeOrderlineQuantity({
            Id: orderline.Id,
            Quantity: orderline.Quantity,
            PO: orderline.PO,
            IsCartOrder: false
          });
          this.refreshOrderlineAndState(orderline, 'Quantity changed successfully');
          if (HasLikeItems(this.orderlines, orderline)) {
            this.getOrderlinesByPO(true);
          }
        } catch (err) {
          this.addAlert(
            'There was an error while changing your quantity, please refresh your page, or contact techincal support if error persists',
            'error'
          );
        }
      }
    }
  }
  setPaymentProfile(val: any) {
    this.paymentProfileId = val;
  }
  async selectRush(val: {
    orderId: string;
    rush: RushOption;
    deliveryOption: number;
    additionalIdsToRush: number[];
  }) {
    if (val.orderId) {
      let orderline = this.orderlines.find(
        (ol: OrderlineDetails) => ol.Id == val.orderId
      );
      if (orderline) {
        orderline.RushOption = val.rush;
        try {
          const { data } = await OrdersService.RushOrderline({
            Id: orderline.Id,
            RushOptionId: val.rush.PublicId,
            ShippingDeliveryOptionId: val.deliveryOption,
            AdditionalOrderlineIdsToRush: val.additionalIdsToRush
          });
          const { Orderline, AffectedOrderlines } = data;
          orderline.DueDate = Orderline.DueDate;
          orderline.IsARush = Orderline.IsARush;
          orderline.RushFee = Orderline.RushFee;
          this.clearCurrentOrder();
          this.getOrderlinesByPO();
        } catch (err) {
          this.addAlert(
            'Something went wrong while adding your rush to this item, please contact technical support.',
            'error'
          );
          console.log(err);
        }
      }
    }
  }
  async deleteOrderline(val: any) {
    if (val) {
      SetSnackBar('Canceling your item...');
      var orderlines = this.orderlines.filter(ol => ol.Id !== val.Id);
      this.setOrderlines(orderlines);
      await OrdersService.DeleteOrderline({
        Id: val.Id,
        HasCompletedRequest: val.AmazonRequest.AmazonRequest.IsComplete
      });
      this.orderlines = orderlines;
      this.setCurrentOrderInStore(this.orderlines);
      this.addAlert('Line item removed from system', 'success');
      if (!this.orderlines[0]) {
        this.$router.push({ name: 'Orders' });
      }
    }
  }
  filterObject(item: any, queryText: string, itemText: string) {
    return (
      item.PartNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
      item.Description.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }
  refreshImageForSearch() {
    this.refreshImg = false;
    setTimeout(() => (this.refreshImg = true), 0.5);
  }
  formatStringLength(value: string, length: number) {
    if (value) {
      return `${value.substring(0, length)}${value.length >= length ? '...' : ''}`;
    }
    return '';
  }
  handleQuantityInput(newValue: any) {
    this.quantity = parseInt(newValue.toString().replace(/\D/g, ''));
  }
  onAddressChange() {
    this.selectedRollDirection = this.selectedAddress.IsCustomerPickup
      ? this.defaultCustomerRollDirection
      : this.selectedAddress.Options.RollDirectionId;
    this.shouldShowAlert = this.selectedRollDirection === 10;
  }
  async getCurrentCustomer() {
    if (!this.currentCustomer.Name) await this.getCurrentCustomerStore();
    let currentCustomer = this.currentCustomer;
    this.defaultCustomerRollDirection = currentCustomer.Options.RollDirection.Position;
  }
  async onUpdateRollDirectionId() {
    let oldAddress = this.selectedAddress;
    await this.getShippingAddresses(false);
    let newAddress = this.shippingAddresses.find((a: any) => a.Id === oldAddress.Id);
    if (newAddress) {
      this.selectedAddress = newAddress;
      this.selectedAddress.Options.RollDirectionId = newAddress.Options.RollDirectionId;
    }
  }
  closeNewLineItemDialog() {
    this.shouldShowNewLineItemDialog = false;
    this.quantity = 0;
    this.selectedItem = null;
    this.selectedRushOption = new RushOption();
    this.orderlines = this.orderlines.filter(
      ol => ol.OrderlinePurchaseStateType != OrderlinePurchaseStateTypes.Create
    );
  }

  /* Loaders */
  async getOrderlinesByPO(shouldUpdateRegularList?: boolean) {
    try {
      this.isGettingOrderDetails = true;
      const { data } = await OrdersService.GetOrderFull(this.$route.params.po);
      if (data) {
        this.orderlines = data.map((ol: any) => (ol = new OrderlineDetails(ol)));
        this.setCurrentOrderInStore(this.orderlines);
      }
      this.isGettingOrderDetails = false;
      if (this.orderlines[0]) {
        if (shouldUpdateRegularList) this.refreshOrderlinesByParameter(this.orderlines);
        this.po = this.orderlines[0].PO;
        this.getPaymentProfileForOrder();
        setTimeout(() => {
          if (this.$refs && this.$refs.OrderSummary) {
            this.$refs.OrderSummary.setOrderlineBreakdown();
            this.$refs.OrderSummary.calculateSubTotal();
          }
        }, 100);
      }
    } catch (err) {
      console.log(err);
      this.addAlert(
        'There was an error loading your order, please refresh your page, or contact technical support if error persists',
        'error'
      );
    }
    (this.orderlines as OrderlineDetails[]).sort(ol => ol.OrderLineNumber).reverse();
    this.isGettingOrderDetails = false;
  }
  async cancelOrder() {
    Confirm(
      async () => {
        this.isCancellingOrder = true;
        try {
          await OrdersService.CancelOrder(this.po, false);
          await this.removeOrdersByPO(this.po);
          this.clearCurrentOrder();
          SetSnackBar('Order Cancelled');
          this.$router.push({ name: 'Orders' });
        } catch (err) {
          if (err.response.status === 400) {
            this.addAlert(
              'Cannot cancel order that is already in production. Please contact info@just1label.com for more information',
              'error'
            );
          }
        }
        this.isCancellingOrder = false;
      },
      'Cancel Order',
      'Are you sure you want to cancel this order?'
    );
  }
  async shouldAddOrderline() {
    Confirm(
      async () => {
        await this.addOrderline();
      },
      'Add To Order',
      'This order will be placed immediately, are you sure you want to continue?'
    );
  }
  async addOrderline() {
    SetSnackBar('Ordering this item...');
    this.isAddingOrderline = true;
    try {
      const profileId = this.paymentMethod.PaymentProfileId;
      let model = {
        Orderlines: this.orderlines.map((ol: OrderlineDetails) =>
          ConversionHelper.convertOrderlineToAddModel(ol)
        ),
        PO: this.po,
        State:
          this.orderlines[0].OrderlinePurchaseStateType ===
          OrderlinePurchaseStateTypes.Create
            ? 'New'
            : 'Edit',
        ShipToId: 4,
        OrderId: this.orderlines.find(ol => !!ol.CustomerOrderId)?.CustomerOrderId || '',
        PaymentMethodProfileId: profileId,
        OrderType: 'Add From Order Details'
      };
      const orderResponse = await OrdersService.PlaceOrder(model);
      const newOrderlines: OrderlineDetails[] = orderResponse.data.map(
        (r: any) => new OrderlineDetails(r)
      );
      this.orderlines = newOrderlines;
      this.setCurrentOrderInStore(newOrderlines);
      this.isAddingOrderline = false;
      this.closeNewLineItemDialog();
      this.addAlert('line item added successfully', 'success');
    } catch (err) {
      const error = err as any;
      if (error.response) {
        this.addAlert(
          `There was a problem adding your order. ${error.response.data.ExceptionMessage}`,
          'error'
        );
      } else {
        this.addAlert(
          'There was a problem adding your order. Please contact technical support',
          'error'
        );
      }
      this.isAddingOrderline = false;
      this.shouldShowNewLineItemDialog = false;
    }
  }
  async generateNewOrderline() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(async () => {
      if (
        !this.selectedItem ||
        !this.selectedItem.Id ||
        !this.quantity ||
        this.quantity! <= 0
      ) {
        return;
      }
      this.orderlines = this.orderlines.filter(ol => ol.Status != 'PENDING');
      const model = ConversionHelper.generateNewOrderlineModel(
        this.selectedItem!.Id,
        Number(this.quantity),
        this.selectedAddress.Id,
        this.orderlines[0].PO
      );
      if (!this.currentCustomer) {
        await this.getCurrentCustomerStore();
      }
      model.RollDirectionId = this.selectedRollDirection!;
      const response = await OrdersService.GenerateOrderlineDefaults(model);
      const newOrderline = new OrderlineDetails(response.data);
      if (newOrderline.Quantity !== this.quantity) {
        return;
      }
      newOrderline.OrderLineNumber =
        (this.orderlines.length >= 1
          ? Math.max.apply(
              Math,
              (this.orderlines as OrderlineDetails[]).map(ol => {
                return ol.OrderLineNumber;
              })
            )
          : 0) + 1;
      newOrderline.OrderlinePurchaseStateType = OrderlinePurchaseStateTypes.Create;
      this.newOrderline = newOrderline;
      this.orderlines = [...this.orderlines, newOrderline];
      this.GeneratePricingData();
      this.LoadRushFeeValues();
    }, this.doneTypingInterval);
  }
  async GeneratePricingData() {
    if (this.orderlines[0]) {
      this.isCalculatingPricingData = true;
      const response = await OrdersService.CalculatePricingData(
        this.orderlines.map(ol => ConversionHelper.convertOrderlineToAddModel(ol))
      );
      const orderlines = response.data.map((ol: any) => new OrderlineDetails(ol));
      const newOrderline = orderlines.find(
        (ol: OrderlineDetails) => ol.Status == 'PENDING'
      );
      this.newOrderline = newOrderline;
      const newOrderlineIndex = this.orderlines.findIndex(ol => ol.Status == 'PENDING');
      newOrderline.OrderlinePurchaseStateType = OrderlinePurchaseStateTypes.Create;
      this.orderlines[newOrderlineIndex] = newOrderline;
      // this.resetFees();
      this.extendedPrice = this.newOrderline.Quantity * this.newOrderline.PriceEach;
      this.setUpFee = this.newOrderline.SetupCosts;
      this.variableDataFee =
        this.newOrderline.Quantity * this.newOrderline.VariableDataPriceEach;
      this.extraRollFee = this.newOrderline.HandApplyFee;
      this.isCalculatingPricingData = false;
    } else {
      this.resetFees();
    }
  }
  openEditPoDialog() {
    this.$refs.OrderEditPo.showDialog();
  }
  onRushSelection() {
    this.rushFee = this.selectedRushOption.RushFee;
    this.newOrderline.RushOption = this.selectedRushOption;
    this.newOrderline.DueDate = new Date(this.selectedRushOption.DeliveryDate);
    this.newOrderline.RushFee = this.selectedRushOption.RushFee;
    this.newOrderline.IsARush = this.selectedRushOption.RushFee > 0;
    this.newOrderline.ShippingDeliveryMethod = this.getDeliveryMethodFromRushDay(
      this.selectedRushOption.DayValue,
      this.selectedAddress.Country.Id > 1
    );
    this.GeneratePricingData();
  }
  resetFees() {
    this.extendedPrice = 0;
    this.setUpFee = 0;
    this.variableDataFee = 0;
    this.rushFee = 0;
    this.extraRollFee = 0;
  }
  async getShippingAddresses(shouldSetDefault: boolean = true) {
    try {
      const response = await AddressService.GetShippingAddressesFull();
      this.shippingAddresses = response.data;
      if (shouldSetDefault) {
        let defaultAddress = this.shippingAddresses.find(a => a.IsDefault);
        this.selectedAddress =
          defaultAddress !== undefined ? defaultAddress : this.shippingAddresses[0];
      }
    } catch (err) {
      this.addAlert(
        `There was an error grabbing your shipping address. 
        Please refresh your page, or contact techincal support if error persists`,
        'error'
      );
    }
  }
  async LoadRushFeeValues() {
    if (this.newOrderline) {
      const response = await OrdersService.GetRushFeeValues(
        ConversionHelper.convertOrderlineToAddModel(this.newOrderline)
      );
      this.rushOptions = response.data.filter((d: any) => d.IsAvailable);
      this.rushOptions = this.rushOptions.map(r => new RushOption(r));
      this.selectedRushOption = this.rushOptions[0];
      if (!this.newOrderline.Item.IsApproved) {
        this.rushHint =
          'If you would like to expedite this item, please approve it first';
      }
    }
  }
  async getPaymentProfileForOrder() {
    const { data: paymentProfile } = await OrdersService.GetPaymentMethodUsedOnOrder(
      this.orderlines[0].CustomerOrderId
    );
    if (paymentProfile) this.paymentMethod = new PaymentProfile(paymentProfile);
  }
  /* Mounted */
  async mounted() {
    this.getOrderlinesByPO();
    this.isGettingCustomerItems = true;
    await this.getCustomerItemsLimited();
    this.getPartNumbers;
    this.isGettingCustomerItems = false;
    this.getShippingAddresses();
  }
  /* Computed */
  get formattedQuantity() {
    return StringHelper.formatQuantity(this.quantity);
  }
  get canAddToOrder() {
    return (
      this.selectedItem &&
      this.selectedAddress &&
      this.quantity! > 0 &&
      this.po &&
      this.isTextInputValid &&
      !this.paymentMethod?.IsExpired &&
      this.selectedRushOption.DayValue > 0
    );
  }
  get calculateSubTotal() {
    return (
      this.extendedPrice +
      this.setUpFee +
      this.variableDataFee +
      this.rushFee +
      this.extraRollFee
    ).toFixed(2);
  }
  get constValues() {
    return ConstValues;
  }
}
